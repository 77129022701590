import { COUPON_OFFERS, REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";
import { RetrieveCoupon } from "../../common/utils/RetrieveCoupon";

function RunForKidsMain() {
  const { loading, coupon } = RetrieveCoupon(COUPON_OFFERS.runforkids);
  const redeemUrl = getRedeemUrlWithContext("run-for-kids");

  return (
    <>
      {!loading && (
          <OnboardingPage
            partnerCfg={GLOBAL_CFG["run-for-kids"]}
            redeemUrl={redeemUrl}
            coupon={coupon}
            rewardId={REWARD_OFFERING.runforkids}
          />
      )}
    </>
  );
}

export default RunForKidsMain;