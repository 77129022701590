import { getSemanticVersion } from "./utils";

export const enum EVENT_TAGS {
  LINK = "link",
  REDIRECT = "redirect",
  ANALYTICS = "analytics",
}

export const enum CLIENT {
  LINKT = "linkt",
  LINKTGO = "linktgo",
}

export const enum REGION {
  NSW = "nsw",
  QLD = "qld",
  VIC = "vic",
}

export const enum REWARD_OFFERING {
  SHELL = "rewards-shell-express",
  shellfuelup = "fuel-up-your-savings",
  europcar = "europcar",
  europcartactical = "europcar-tactical",
  starcarwash = "star-car-wash",
  starcarwashtactical = "star-car-wash-tactical",
  bookingdotcom = "booking-dot-com",
  comp1000prizes = "comp-1000-prizes",
  compwinchoosefly = "comp-winchoosefly",
  secureparking = "secure-parking",
  mycar = "mycar",
  mycartactical = "mycar-tactical",
  globalescapes = "global-escapes",
  runforkids = "run-for-kids",
  supercheapauto = "supercheap-auto",
  supercheaptrade = "supercheap-trade",
  sydneyairport = "sydney-airport",
  sydneyairporttactical = "sydney-airport-tactical",
  tourdebrisbane = "tour-de-brisbane",
}

export const enum COUPON_OFFERS {
  supercheapauto = "supercheap-auto-1x0x1",
  supercheaptrade = "supercheap-trade-1x0x0",
  globalescapes = "global-escapes-1x0x0",
  runforkids = "run-for-kids-1x0x0",
  sydneyairport = "sydney-airport-1x0x0",
  sydneyairporttactical = "sydney-airport-1x0x1",
}

export const enum PLATFORM {
  ANDROID = "android",
  IOS = "ios",
}

export const ANALYTICS_CLIENT_ID_MAP: { [key: string]: string } = {
  linktgo: "LG",
  linkt: "LA",
};

export const enum FLOW {
  ONBOARDING = "onboarding",
  REDEEM = "redeem",
  PENDING = "loading",
}

// Setup global constants
// eslint-disable-next-line no-restricted-globals
const queryParams = new URLSearchParams(location.search);
const dataString = sessionStorage.getItem("data"); // Get data object which is injected by the app.
const client = queryParams.get("client") as CLIENT;
export const GLOBAL_CONST = {
  REWARDS_ID: REWARD_OFFERING.SHELL,
  CLIENT_ID: client,
  PLATFORM: (queryParams.get("platform") as PLATFORM) ?? PLATFORM.ANDROID,
  FLOW_PENDING: FLOW.PENDING, // Default atom store value.
  FLOW: queryParams.get("flow") ?? FLOW.ONBOARDING, // Default after assets are loaded, and flow not explicitly specified by client.
  APP_VERSION: getSemanticVersion(queryParams.get("appVersion")),
  REGION: (queryParams.get("region") as REGION) ?? REGION.NSW,
  ANALYTICS_CLIENT: ANALYTICS_CLIENT_ID_MAP[client],
  ACCESS_TOKEN: sessionStorage.getItem("rewardsToken"), // Get access token which is injected by the app.
  DATA: dataString ? JSON.parse(dataString) : ({} as { [key: string]: string }),
};

export const PLACEHOLDER_BARCODE = "00000000000000000000";
